// List of error ID  as defined by backend
export enum API_ERRORS_ID {
  // SESSION AND TOKEN
  API_AUTH_TOKEN_DISTINCT_DOMAIN = 'authErrorHackToken', // The domain the client token is distinct from current domain
  API_AUTH_TOKEN_DISCARDED_ERROR = 'authErrorDiscardedToken', // The token is not valid any mmore
  API_AUTH_TOKEN_WRONG_ERROR = 'authErrorWrongToken', // the  token is wrong
  API_SESSION_FATAL_ERROR = 'sessionFatalError',
  // API_SESSION_CLIENT_MISMATCH = 'sessionClientMismatch',

  API_AUTH_LOGIN_NOT_FOUND_IN_DB = 'loginNotFoundInDB',
  API_AUTH_LOGIN_FOUND_IN_DB = 'loginFoundInDB',

  // FILES AND IMAGES
  // https://github.com/sinsunsan/arsultima/issues/197
  API_FILE_SIZE_TOO_HIGH_ERROR = 'fileSizeTooHigh',
  API_FILE_EXTENSIONS_NOT_SUPPORTED = 'imgNameWrongExtension',
  API_FILE_EXTENSIONS_NOT_SUPPORTED_HERE = 'imgMimeTypeNotSupportedHere',
  API_MIME_TYPE_DOES_NOT_FIT_EXTENSION_FILE = 'fileMimeTypeDoesntFitExtension',
  API_MIME_TYPE_DOES_NOT_FIT_EXTENSION_IMG = 'imgMimeTypeDoesntFitExtension',
  API_SUB_DOMAIN_EXIST = 'loginUnavailable',

  // MAIL SENDING
  API_MAIL_SEND_FAILURE = 'mailFailure',

  // STATS
  API_NO_ITEMS = 'itemEmpty', // occur in stats

  VOID = '',
}

export const API_TOKEN_ERRORS = [
  API_ERRORS_ID.API_AUTH_TOKEN_DISCARDED_ERROR,
  API_ERRORS_ID.API_AUTH_TOKEN_WRONG_ERROR,
  API_ERRORS_ID.API_SESSION_FATAL_ERROR,
  API_ERRORS_ID.API_AUTH_TOKEN_DISTINCT_DOMAIN,
  // API_ERRORS_ID.API_SESSION_CLIENT_MISMATCH,
];

// List of error message as defined by Front
export const ArsultimaErrorTypes = {
  DEFAULT_ERROR: 'DEFAULT_ERROR',

  // Authentication
  TOKEN_EXPIRED: 'TOKEN_EXPIRED',
  NO_ACCOUNT_FOUND: 'NO_ACCOUNT_FOUND',
  SIGNUP_ERROR: 'SIGNUP_ERROR',
  ACCOUNT_ALREADY_EXIST: 'ACCOUNT_ALREADY_EXIST',

  TOO_BIG_FILE_SIZE: 'TOO_BIG_FILE_SIZE',
  FILE_EXTENSION_NOT_SUPPORTED: 'FILE_EXTENSION_NOT_SUPPORTED',
  MIME_TYPE_DOES_NOT_FIT_EXTENSION: 'MIME_TYPE_DOES_NOT_FIT_EXTENSION',
  MAIL_SEND_FAILURE: 'MAIL_SEND_FAILURE',
  COLLECTION_DOMAIN_EXIST: 'COLLECTION_DOMAIN_EXIST',
  NO_ITEMS_IN_COLLECTION: 'NO_ITEMS_IN_COLLECTION',
};

// Mapping between fron and API error messages
export const ARSULTIMA_ERRORS_API_ID_TO_FRONT_ID = {
  // SIGNUP - SIGNIN
  [API_ERRORS_ID.API_AUTH_TOKEN_DISCARDED_ERROR]: ArsultimaErrorTypes.TOKEN_EXPIRED,
  [API_ERRORS_ID.API_AUTH_TOKEN_WRONG_ERROR]: ArsultimaErrorTypes.TOKEN_EXPIRED,
  [API_ERRORS_ID.API_AUTH_LOGIN_NOT_FOUND_IN_DB]: ArsultimaErrorTypes.NO_ACCOUNT_FOUND,
  [API_ERRORS_ID.API_AUTH_LOGIN_FOUND_IN_DB]: ArsultimaErrorTypes.ACCOUNT_ALREADY_EXIST,
  [API_ERRORS_ID.API_SUB_DOMAIN_EXIST]: ArsultimaErrorTypes.COLLECTION_DOMAIN_EXIST,

  // UPLOAD
  [API_ERRORS_ID.API_FILE_SIZE_TOO_HIGH_ERROR]: ArsultimaErrorTypes.TOO_BIG_FILE_SIZE,
  [API_ERRORS_ID.API_MIME_TYPE_DOES_NOT_FIT_EXTENSION_FILE]: ArsultimaErrorTypes.MIME_TYPE_DOES_NOT_FIT_EXTENSION,
  [API_ERRORS_ID.API_MIME_TYPE_DOES_NOT_FIT_EXTENSION_IMG]: ArsultimaErrorTypes.MIME_TYPE_DOES_NOT_FIT_EXTENSION,
  [API_ERRORS_ID.API_FILE_EXTENSIONS_NOT_SUPPORTED]: ArsultimaErrorTypes.FILE_EXTENSION_NOT_SUPPORTED,
  [API_ERRORS_ID.API_FILE_EXTENSIONS_NOT_SUPPORTED_HERE]: ArsultimaErrorTypes.FILE_EXTENSION_NOT_SUPPORTED,

  // MAIL
  [API_ERRORS_ID.API_MAIL_SEND_FAILURE]: ArsultimaErrorTypes.MAIL_SEND_FAILURE,

  // STATS
  [API_ERRORS_ID.API_NO_ITEMS]: ArsultimaErrorTypes.NO_ITEMS_IN_COLLECTION,
};

// Show only know API errors to client
// TRUE is the default to prevent to non-revelant errrors from API
// But you could change to FALSE to show all errors
export const SHOW_MESSAGES_ONLY_FOR_KNOWN_API_ERRORS = false;

export const SAVE_SUBMISSION_ERROR = 'saveSubmissionError';
