<h1 mat-dialog-title>
  <div class="row">
    <div class="col-md-10 col-8">
      <span class="text-dialog-header">
        {{ title }}
      </span>
    </div>
    <div class="col-md-2 col-4 text-end">
      <mat-icon [mat-dialog-close]>close</mat-icon>
    </div>
  </div>
</h1>
<hr />
<div class="signin-modal container mt-4">
  <!-- SIGNIN FORM -->
  <div class="row" *ngIf="isSigninForm">
    <div>
      <form [formGroup]="loginForm" (submit)="submit()" errorTailor>
        <div class="form-field">
          <mat-form-field class="form-field">
            <input matInput type="email" placeholder="Email" formControlName="email" />
          </mat-form-field>
        </div>
        <div class="form-field">
          <mat-form-field class="form-field">
            <input
              matInput
              [type]="getPasswordType(passwordTypes.signin)"
              [placeholder]="'connect.password' | transloco"
              formControlName="password"
            />

            <mat-icon
              matSuffix
              class="arsu-icon-grey"
              (click)="togglePasswordVisibility(passwordTypes.signin, $event)"
              >{{ getPasswordIcon(passwordTypes.signin) }}</mat-icon
            >
          </mat-form-field>
        </div>

        <div class="text-end switch-reset text-form-label c-pointer" (click)="showPasswordReset()">
          <a
            ><i class="fa fa-question-circle m-r-xs"></i><span>{{ 'connect.passwordReset' | transloco }}</span></a
          >
        </div>

        <div>
          <button
            class="submit full-width"
            type="submit"
            mat-flat-button
            color="primary"
            [disabled]="loginForm.invalid"
          >
            {{ 'connect.signin' | transloco }}
          </button>
        </div>

        <div class="switch-signup text-center m-t-lg c-pointer text-form-label" (click)="showSignup()">
          <a
            ><span>{{ 'connect.dontHaveAccount' | transloco }}</span></a
          >
        </div>
      </form>
    </div>
  </div>

  <!-- SIGNUP FORM -->
  <div class="row reset-container" *ngIf="isSignupForm">
    <p class="text-big-text">{{ 'connect.fillForm' | transloco }}.</p>
    <form [formGroup]="signupForm" (submit)="signupSubmit()">
      <mat-form-field class="form-field">
        <input matInput type="email" [placeholder]="'connect.email' | transloco" formControlName="email" />
        <ng-container
          *ngIf="
            signupForm.controls.email.value && signupForm.controls.email.touched && signupForm.controls.email.invalid
          "
        >
        </ng-container>
      </mat-form-field>

      <mat-form-field class="form-field">
        <input
          matInput
          [type]="getPasswordType(passwordTypes.signupNormalAndConfirm)"
          [placeholder]="'signup.passwordPlaceholder' | transloco"
          formControlName="password"
        />
        <mat-icon
          matSuffix
          class="arsu-icon-grey"
          (click)="togglePasswordVisibility(passwordTypes.signupNormalAndConfirm, $event)"
          >{{ getPasswordIcon(passwordTypes.signupNormalAndConfirm) }}</mat-icon
        >
        <ng-container
          *ngIf="
            signupForm.controls.password.value &&
            signupForm.controls.password.touched &&
            signupForm.controls.password.invalid
          "
        >
        </ng-container>
      </mat-form-field>

      <mat-form-field class="form-field">
        <input
          matInput
          [type]="getPasswordType(passwordTypes.signupNormalAndConfirm)"
          [placeholder]="'signup.passwordConfirm' | transloco"
          formControlName="passwordConfirm"
        />
        <mat-icon
          matSuffix
          class="arsu-icon-grey"
          (click)="togglePasswordVisibility(passwordTypes.signupNormalAndConfirm, $event)"
          >{{ getPasswordIcon(passwordTypes.signupNormalAndConfirm) }}</mat-icon
        >
      </mat-form-field>
      <mat-error *ngIf="signupForm.errors?.passwordsNotMatch">{{
        'connect.error.invalidPasswordMatch' | transloco
      }}</mat-error>

      <mat-form-field class="form-field">
        <input matInput type="text" [placeholder]="'signup.domain' | transloco" formControlName="subdomain" />
        <ng-container
          *ngIf="
            signupForm.controls.subdomain.value &&
            signupForm.controls.subdomain.touched &&
            signupForm.controls.subdomain.invalid
          "
        >
        </ng-container>
      </mat-form-field>

      <!-- <a (click)="debugForm()">debug form </a> -->

      <button class="submit full-width" type="submit" mat-flat-button color="primary" [disabled]="signupForm.invalid">
        {{ 'connect.createAccount' | transloco }}
      </button>

      <div class="switch-signin text-center m-t-lg c-pointer text-form-label" (click)="showSignin()">
        <a
          ><span>{{ 'connect.signinInstead' | transloco }}</span></a
        >
      </div>
    </form>
  </div>

  <!-- RESET FORM -->
  <div class="row reset-container" *ngIf="isResetForm">
    <p class="text-big-text">{{ 'connect.enterEmail' | transloco }}.</p>
    <form [formGroup]="resetForm" (submit)="resetSubmit()">
      <div class="form-field m-b-md">
        <mat-form-field class="form-field">
          <input matInput type="email" placeholder="Email" formControlName="email" />
        </mat-form-field>
      </div>
      <button class="submit full-width" type="submit" mat-flat-button color="primary" [disabled]="resetForm.invalid">
        {{ 'connect.emailLink' | transloco }}
      </button>

      <div class="switch-signin text-center m-t-lg c-pointer text-form-label" (click)="showSignin()">
        <a
          ><span>{{ 'connect.rememberPassword' | transloco }}</span></a
        >
      </div>
    </form>
  </div>
</div>
